import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const AktywnyPage = () => (
  <Layout>
    <SEO title="Aktywny Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/aktywny-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>GDAŃSK WITA Z OTWARTYMI RAMIONAMI</h3>
          <h1>Aktywny Weekend w Gdańsku!</h1>
          <p>
            Oferta jest skierowana przede wszystkim do tych, którzy poszukują
            aktywnej i dobrej zabawy w czasie weekendu. Jest to doskonały wybór
            dla osób, które chcą aktywnie spędzić wolny czas. Polecamy ten
            program szczególnie grupie przyjaciół bądź męskiemu gronu, które
            świętuje wieczór kawalerski. Paintball i strzelnica to najlepsze
            zajęcia do wspólnego spędzania czasu i integracji. Niewykluczone, że
            chętnie spróbujecie też jazdy na skuterach wodnych. Z kolei
            przejażdżka quadami to także solidna dawka emocji – tyle, że na
            lądzie! Gdańsk to idealne miasto na krótki wypad gdzie oprócz
            miejskich rozrywek jest także możliwość zajęć na świeżym powietrzu.
            Wieczorem zapraszamy do lokalnych browarni gdzie nie brakuje
            wyśmienitych trunków i smacznych potraw. Do zobaczenia w Gdańsku!
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Degustacja piwa w lokalnych browarach</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>Zabawa w Centrum paintball’a</li>
                <li>Wypoczynek na strzelnicy</li>
                <li>Przerwa na obiad w Sopocie</li>
                <li>Przejażdżka na skuterach wodnych</li>
                <li>Wieczorna degustacja piwa</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>Przejażdżka quadami</li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AktywnyPage
